<!-- 反腐条例 -->
<template>
	<div class="responsibility">
	    <div class="content" v-if="config.cc5&&config.cc5.isShow">
	        <div class="wCenter">
	            <div >
	                <p class="text" v-for="(item, index) in config.cc5.descr.split('\n')" :key="index">{{item}}</p>
	            </div>
	        </div>
	    </div>
	    <div class="content" v-if="config.cc6&&config.cc6.isShow">
	        <div class="wCenter">
	            <p class="title">{{config.c2.title}}</p>
	             <p class="text" v-for="(item, index) in config.cc6.descr.split('\n')" :key="index">{{item}}</p>
	        </div>
	    </div>
	    <div class="content" v-if="config.cc7&&config.cc7.isShow">
	        <div class="wCenter">
	            <p class="title">{{config.cc7.title}}</p>
	                <p class="text" v-for="(item, index) in config.cc7.descr.split('\n')" :key="index">{{item}}</p>
	        </div>
	    </div>
	    <div class="content" v-if="config.cc8&&config.cc8.isShow">
	        <div class="wCenter">
	            <p class="title">{{config.cc8.title}}</p>
	         
	                <p class="text" v-for="(item, index) in config.cc8.descr.split('\n')" :key="index">{{item}}</p>
	        </div>
	    </div>
	</div>
<!-- <div class="regulations">
    <div class="wCenter">
        <div class="regulationsContent">
            <div v-if="config.cc5&&config.cc5.isShow">
                <p class="title1" v-for="(item, index) in config.cc5.descr.split('\n')" :key="index">{{item}}</p>
            </div>
            <div v-if="config.cc6&&config.cc6.isShow">
                <p class="title2">{{config.cc6.title}}</p>
                <p class="content" v-for="(item, index) in config.cc6.descr.split('\n')" :key="index">{{item}}</p>
            </div>
            <div v-if="config.cc7&&config.cc7.isShow">
                <p class="title3">{{config.cc7.title}}</p>
                <p class="content" v-for="(item, index) in config.cc7.descr.split('\n')" :key="index">{{item}}</p>
            </div>
            <div v-if="config.cc8&&config.cc8.isShow">
                <p class="title4">{{config.cc8.title}}</p>
                <p class="content" v-for="(item, index) in config.cc8.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
</div> -->
</template>

<script>
import {
    getPageConfigByCode,
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'responsibility/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
	.responsibility {
	    .content {
	        padding: 0.77rem 0;
	
	        @media (max-width: 767px) {
	            padding: 0.3rem 0;
	        }
	
	        .title {
	            color: #333333;
	            font-size: 0.3rem;
	            font-weight: bold;
	            margin-bottom: 0.5rem;
	
	            @media (max-width: 767px) {
	                margin-bottom: 0.3rem;
					font-size: 0.4rem;
	            }
	        }
	
	        .text {
	            color: #333333;
	            font-size: 0.24rem;
	            line-height: 2;
	            margin-bottom: 0.2rem;
	            text-align: justify;
				@media (max-width: 767px) {
				     font-size: 0.38rem;
				}
	        }
	    }
	
	    .content:nth-child(odd) {
	        background: #eeeeee;
	    }
	}
</style>
